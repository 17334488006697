import React from "react";
import "./skillsInfoCard.scss"
// @ts-ignore
function SkillsInfoCard({heading, skills}) {
    return (
        <div className="skills-info-card">
            <h6>{heading}</h6>
            <div className="skills-info-content">
                {skills.map((item: any, index: any) => (
                    <React.Fragment key={`skill_${index}`}>
                        <div  className="skills-info ">
                            <p>{item.skill}</p>
                            <p className="percentage">{item.percentage}</p>
                        </div>

                        <div className="skill-progress-bg">
                            <div className="skill-progress" style={{width: item.percentage}}/>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default SkillsInfoCard